import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-iframe-component',
    templateUrl: './iframe.component.html',
    styleUrls: ['./iframe.component.scss'],
})
export class IframeComponent {
    @Input() url: string;
    @Input() dismiss: () => any;
}
