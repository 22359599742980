import {Component} from '@angular/core';
import {APIService} from '../../../../@core/api.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {RequestHandler} from '../../../../@core/utils/request-handler';
import {take} from 'rxjs/operators';

@Component({
    selector: 'app-recaptcha-solver',
    templateUrl: './recaptcha-solver.component.html',
})
export class RecaptchaSolverComponent {
    recaptchaPuzzleId: number;
    recaptchaPuzzleImg: string;
    recaptchaPuzzleSolution: string;

    reCaptchaSolutionRequest = new RequestHandler(
        (id: number, solution: string) => this.apiService.CaptchaViewSet.update(id, {text: solution}),
        {
            showErrorToast: true,
            successMessage: 'ReCaptcha solved successfully',
        }
    );

    reCaptchaRequest = new RequestHandler(
        (id: number) => this.apiService.CaptchaViewSet.retrieve(id),
        {
            showErrorToast: true,
        }
    );

    constructor(private apiService: APIService,
                private activeModal: NgbActiveModal) {
    }

    ngOnInit(): void {
        this.reCaptchaRequest.call(this.recaptchaPuzzleId).pipe(take(1)).subscribe(data => {
            this.recaptchaPuzzleImg = `data:image/png;base64,${data.image}`;
        });
    }

    solveReCaptcha() {
        this.reCaptchaSolutionRequest.call(this.recaptchaPuzzleId, this.recaptchaPuzzleSolution).pipe(take(1)).subscribe(() => {
            this.activeModal.dismiss();
        });
    }

    cancelReCaptcha() {
        this.activeModal.dismiss();
    }
}
