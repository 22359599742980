import {debounceTime} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {APIService, UserSettingsSerializer} from './api.service';
import {UserAuthService} from './user-auth.service';
import {ReplaySubject, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserSettingsService {
    private _settings: UserSettingsSerializer;
    settings: ReplaySubject<UserSettingsSerializer> = new ReplaySubject(1);
    private _retrievedSettings: UserSettingsSerializer;
    private _settingsUpdates: UserSettingsSerializer;
    private _sendSettings = new Subject<any>();

    constructor(private api: APIService,
                private userAuth: UserAuthService) {
        this.userAuth.user.subscribe(user => {
            if (user) {
                this.api.UserSettingsViewSet.retrieve().subscribe(x => {
                    this._updateSettings(x, true);
                });
            } else {
                this._updateSettings(null);
            }
        });
        this._sendSettings.pipe(debounceTime(800)).subscribe(settingsUpdates => {
            Object.keys(settingsUpdates).forEach(key => {
                if (settingsUpdates[key] == this._retrievedSettings[key]) delete settingsUpdates[key];
            });
            if (Object.keys(settingsUpdates).length) {
                this.api.UserSettingsViewSet.partial_update(settingsUpdates).subscribe(x => {
                    this._updateSettings(x, true);
                });
            }
        });
    }

    changeSettings(settings: UserSettingsSerializer) {
        this._updateSettings(settings);
        this._settingsUpdates = Object.assign(this._settingsUpdates || {}, settings);
        this._sendSettings.next(this._settingsUpdates);
    }

    getSetting(key: string): any {
        return this._settings ? this._settings[key] : undefined;
    }

    private _updateSettings(settings, updateRetrievedSettings = false) {
        this._settings = settings ? Object.assign(this._settings || {}, settings) : null;
        if (updateRetrievedSettings) this._retrievedSettings = {...this._settings};
        this.settings.next(this._settings);
    }
}
