<div class="modal-header">
    <h4 class="modal-title" id="modal-title" data-test-id="refresh-modal">Let's solve a ReCaptcha!</h4>
</div>
<form #f="ngForm" (ngSubmit)="solveReCaptcha()">
    <div class="modal-body">
        <div class="form-group">
            <img *ngIf="!reCaptchaRequest.isPending; else loading"
                 class="mx-auto d-block"
                 [attr.src]="recaptchaPuzzleImg|safe">
        </div>
        <div class="form-group">
            <input class="form-control"
                   [maxlength]="10"
                   required="true"
                   [(ngModel)]="recaptchaPuzzleSolution"
                   name="recaptchaPuzzleSolution"
                   placeholder="Solution">
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-end">
        <button type="button"
                class="btn btn-outline-danger"
                (click)="cancelReCaptcha()">
            Cancel
        </button>
        <button [disabled]="!f.valid"
                [appUnderProcessing]="reCaptchaSolutionRequest.isPending"
                type="submit"
                class="btn btn-primary">
            Save
        </button>
    </div>
</form>

<ng-template #loading>
    <app-spinner></app-spinner>
</ng-template>
