import {InjectionToken, ModuleWithProviders, NgModule} from '@angular/core';
import {GRPCWebOptions} from './grpc-web.options';
import {GRPCWebTransport} from './grpc-web.transport';
import {rpcServices} from '@arsana/apis';

export const GRPC_WEB_TRANSPORT_OPTIONS = new InjectionToken<GRPCWebOptions>('GRPCWebOptions');

@NgModule()
export class GRPCWebModule {
    static forRoot(grpcWebOptions: GRPCWebOptions): ModuleWithProviders<GRPCWebModule> {
        return {
            ngModule: GRPCWebModule,
            providers: [
                {
                    provide: GRPC_WEB_TRANSPORT_OPTIONS,
                    useValue: grpcWebOptions,
                },
                GRPCWebTransport,
                ...rpcServices.map(x => ({provide: x, deps: [GRPCWebTransport]})),
            ],
        };
    }
}
