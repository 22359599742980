import {Injectable} from '@angular/core';
import {
    APIService,
    BillingCodeCategorySerializer,
    NonModelUserSerializer,
    PhysicianOrderActionSerializer,
    TaskActionSerializer,
    TaskCreateSerializer,
    TaskSimpleSerializer,
    PatientTaskDetailsSerializer,
    TaskViewSetQueryParams, PatientDocumentCDATransmitSerializer,
} from './api.service';
import {Patient, RelatedPatient} from '../models/patient';
import {Observable, of, Subject, combineLatest, throwError} from 'rxjs';
import {catchError, filter, map, share, switchMap, take, tap} from 'rxjs/operators';
import {UserAuthService} from './user-auth.service';
import {OverlayService} from './overlay.service';
import {NotificationsService} from './notifications.service';
import {RepoAction} from './utils/cache-base';
import {PATIENT_DETAIL_REPO_KEYS, PatientDetailInvalidateKey, PatientService} from './patient.service';
import {FollowingService} from './following.service';
import {FormDefinition} from './forms/form-group.service';
import {FilterService} from './filter.service';
import {Task} from '../models/models';
import {EditorOptions} from '../portal/slide-panel/task-editor.service';
import {getId} from '../utils/type.utils';
import {TaskTypeDefinition} from './api.task_types';
import {DownloadService} from './download.service';
import {ToastService} from './toast.service';
import {catchAndPropagateError} from '../utils/observable.utils';
import {PharmacyService} from './pharmacy.service';
import {assignOrCreate} from '../models/model-base';
import {TzDatePipe} from '../@theme/pipes/tz-date.pipe';
import {PagedCacheRepo} from './utils/paged-cache-repo';
import {ConfirmService} from '../portal/@portal-shared/confirm/confirm.service';
import {Human} from '../models/human';
import {TaskTypeService} from './task-type.service';
import {MethodCache, MethodUtils} from '../utils/accessor.utils';
import {ContentDefinitions, ReviewDefinitions} from '../definitions/definitions';
import {CcdaType} from '../portal/@portal-shared/ccda-transmit/ccda-transmit.component';
import {BILLING_STATUSES} from './billing_statuses';

const TTL_SECS = 20;

const taskCacheIdSegmentsFn: MethodUtils.CacheIdSegmentsFn = (args: TaskCacheFilters) => [
    args.patient,
    args.type,
    args.physician_team__specialty,
    args.facility,
    args.organization,
    args.user,
    args.physician,
    args.start_date,
    args.end_date,
];

export const REVIEW_MAP: {[key in keyof TaskCreateSerializer]: {cacheKey: PatientDetailInvalidateKey; reviewFieldKey: string; actionEntryKey: string}} = {
    problem_list: {cacheKey: 'diagnoses', reviewFieldKey: 'problem_list', actionEntryKey: ContentDefinitions.DIAGNOSIS.actionEntryKey},
};

export const VITAL_EXAM_KEYS: PatientDetailInvalidateKey[] = ['vitalExams', 'vitalExams180', 'vitalExams90', 'vitalExams30', 'vitalExams7'];

export const BILLING_STATUS_OPTIONS = Object.entries(BILLING_STATUSES).map(([id, label]) => ({id, label}));

Object.entries(ReviewDefinitions).forEach(([key, def]) => {
    const cacheKey = PATIENT_DETAIL_REPO_KEYS[key];
    if (cacheKey) {
        REVIEW_MAP[def.reviewFieldKey] = {
            cacheKey,
            reviewFieldKey: def.reviewFieldKey,
            actionEntryKey: def.actionEntryKey,
        };
    }
});

type TaskCacheFilters = Pick<TaskViewSetQueryParams, 'patient' | 'type' | 'physician_team__specialty' | 'facility' | 'organization' | 'user' | 'physician' | 'start_date' | 'end_date'>;

export interface TaskType extends TaskTypeDefinition {
    roles: string[];
    implicitRoles: string[];
    implicitDuration: number;
    implicitFax: boolean;
    implicitSms: boolean;
    implicitBillingCategory: BillingCodeCategorySerializer;
    implicitBillingPlaceholderText: string;
    implicitBillingNotePlaceholderText: string;
    formDefinition?: FormDefinition;
}

export interface TaskTypeFlattened extends TaskType {
    level: number;
    billingCodeCategoryName?: string;
}

export interface TaskTypeMerged extends TaskType {
    path: TaskType[];
    label: string;
    category: string;
    placeholder_billing_description: string;
    placeholder_note_description: string;
}

export interface TaskTypesOutput {
    nested?: TaskType[];
    merged?: TaskTypeMerged[];
    mergedOptions?: TaskTypeMerged[];
    flattened?: TaskTypeFlattened[];
}

export interface TaskTypeDetails { //TODO rename this interface
    fax?: boolean;
    sms?: boolean;
    billable?: boolean;
    reasons?: string[];
    error?: string;
    billing_code_category_names?: string[];
    taggable_users?: NonModelUserSerializer[];
}

export type TaskInit = TaskCreateSerializer;

@Injectable({
    providedIn: 'root',
})
export class CCMService {
    billingCategories$ = this.taskTypeService.billingCategories$;
    taskTypesFlattened$ = this.taskTypeService.taskTypesFlattened$;
    taskTypesMerged$ = this.taskTypeService.taskTypesMerged$;
    taskTypesMergedOptions$ = this.taskTypeService.taskTypesMergedOptions$;
    taskTypesMergedOptionsWAcute$ = this.taskTypeService.taskTypesMergedOptionsWAcute$;
    taskUpdates$ = new Subject<{event: RepoAction; task: Partial<Task>}>();
    initTaskEdit$ = new Subject<{task: Task | TaskInit; options: EditorOptions}>();
    autoTagRoles = ['ROLE_NURSING_DIRECTOR', 'ROLE_FREEMIUM_NURSING_DIRECTOR', 'ROLE_ARSANAMD_NURSING'];
    private _patients: Patient[] = [];
    private _user;
    private _notePageSize = 10;

    constructor(private api: APIService,
                private toastService: ToastService,
                private userAuth: UserAuthService,
                private overlayService: OverlayService,
                private notiService: NotificationsService,
                private followingService: FollowingService,
                private patientService: PatientService,
                private pharmacyService: PharmacyService,
                private tzDate: TzDatePipe,
                private confirmService: ConfirmService,
                private filterService: FilterService,
                private ds: DownloadService,
                private taskTypeService: TaskTypeService) {
        this.userAuth.user.subscribe(u => {
            this._user = u;
            this._patients = [];
            if (u) {
                if (u.permissions.includes('PERMISSION_READ_TASKS')) {
                    this.taskTypeService.getTaskTypes();
                    this.getBillingCategories();
                } else {
                    this._patients = [];
                }
            }

            this._getTaskRepos().forEach(repo => repo.repoValue.reset());
            MethodUtils.clearCache(this);
        });
        this.notiService.broadcast.pipe(filter(n => ['ccm_note_tagged', 'ccm_note'].includes(n.notification_type))).subscribe(n => {
            n.object$.pipe(take(1)).subscribe(task => {
                this.taskUpdates$.next({event: 'add', task});
            });
        });
        this.taskUpdates$.subscribe(x => {
            this.followingService.onTaskUpdate(x);
            this._updatePatientTasks(x);
            const patientId = getId(x.task?.patient);
            if (patientId) {
                const pd = this.patientService.getPatientDetail(patientId);
                (['patient', 'lastHPTask', 'lastRoutineTask'] as PatientDetailInvalidateKey[]).forEach(key => pd.invalidate(key));
            }
        });
    }

    // TODO should this be moved?
    getBillingCategories() {
        this.api.BillingCodeCategoryViewSet.list().subscribe(res => {
            this.billingCategories$.next(res);
        });
    }

    addTask(task: TaskCreateSerializer, showToast = true) {
        return this.saveTask(task, null, showToast, 'ccm-task-created');
    }

    updateTask(task: Partial<TaskCreateSerializer>, id?: number) {
        return this.saveTask(task, id);
    }

    saveTask(task: Partial<TaskCreateSerializer>, id?: number, showToast = true, toastMessageClass: string = null, emitUpdate = true) {
        if (!id) id = task.id;
        const event = id ? 'update' : 'add';

        const obs$: Observable<Task> = (id ? this.api.TaskViewSet.partial_update(id, task) : this.api.TaskViewSet.create(task)).pipe(
            catchError(err => {
                if (err.error?.physician_team?.message === 'multiple_physician_teams') {
                    return this.confirmService.requestConfirm({
                        title: 'Select Physician Team',
                        body: "Please select which Physician's Team you're registering this Task for:",
                        list: (err.error.physician_team.options as any[]).map(x => ({
                            value: x.id as number,
                            displayName: `${Human.getName(x.user)} (${x.user.email})`,
                        })),
                        confirmBtn: {colorClass: 'primary'},
                    }).pipe(switchMap(physicianTeamId => {
                        task.physician_team = physicianTeamId;
                        return this.saveTask(task, task.id, false, null, false);
                    }));
                }

                return throwError(err);
            }),
            map(t => assignOrCreate(Task, t)),
            tap(() => MethodUtils.clearCacheKey(this.userAuth, 'canEditTask$')),
            switchMap(t => this.pharmacyService.handleTask(t)),
            switchMap(t => {
                if (t.meta?.saveAgain) {
                    t.meta.saveAgain = false;
                    return this.saveTask(task, t.id, false, toastMessageClass, false);
                }
                return of(t);
            }),
            share(),
        );

        obs$.pipe(take(1)).subscribe(res => {
            if (emitUpdate) this.taskUpdates$.next({event, task: res});
            if (showToast) this.toastService.success(`${id ? 'Updated' : 'Created'} task successfully`, null, {messageClass: toastMessageClass});
        }, () => this.toastService.error('An error occurred while trying to save task'));

        return obs$;
    }

    findNewEntries(obj: any, path: (string | number)[] = [], result: {path: (string | number)[]; value: any}[] = []) {
        Object.entries(obj).forEach(([key, value]) => {
            if (value && !['string', 'number'].includes(typeof value)) {
                if (typeof value['id'] === 'number' && value['id'] < 0 || value['__newEntry']) {
                    result.push({path: [...path, key], value});
                } else {
                    this.findNewEntries(value, [...path, key], result);
                }
            }
        });
        return result;
    }

    getEntryIds(task: any) {
        const entryIds: {[key in keyof TaskCreateSerializer]: Set<number>} = {};
        Object.entries(task).forEach(([key, value]) => {
            if (REVIEW_MAP[key] && value && value['actions']?.length) {
                entryIds[REVIEW_MAP[key].reviewFieldKey] = new Set<number>();
                value['actions'].forEach(action => entryIds[REVIEW_MAP[key].reviewFieldKey].add(getId(action[REVIEW_MAP[key].actionEntryKey])));
            }
        });
        return Object.keys(entryIds).length ? entryIds : null;
    }

    removeTask(task: Partial<Task>): Observable<any> {
        const obs$ = this.api.TaskViewSet.destroy(task.id).pipe(share());
        obs$.subscribe(() => {
            this.toastService.success('Removed task successfully');
            this.taskUpdates$.next({event: 'remove', task});
        }, () => this.toastService.error('An error occurred while trying to remove task'));
        return obs$;
    }

    getPatient(id: number): Patient {
        return this._patients ? this._patients.find(x => x.id == id) : undefined;
    }

    //TODO: check this method can be removed
    resolvePatient(id: number): Observable<Patient> {
        if (!this._user) return;

        const cachedPatient = this.getPatient(id);
        if (cachedPatient) return of(cachedPatient);

        return this.patientService.getPatientDetail(id).patient$.pipe(take(1), map(
            (patient: Patient) => {
                if (!this.getPatient(id)) {
                    this._patients.push(patient);
                }
                return patient;
            }
        ));
    }

    checkTaskOptions(patient: RelatedPatient, date: string, type: string, physician_team?: number, sourcePatient?: RelatedPatient): Observable<TaskTypeDetails> {
        const obs$ = new Subject<TaskTypeDetails>();
        const patientId = getId(patient);
        const sourcePatientId = getId(sourcePatient);
        this.resolvePatient(getId(patient)).pipe(take(1)).subscribe(p => {
            if (p) {
                this.api.PatientViewSet.task_details(patientId, {
                    date,
                    type,
                    physician_team,
                    source_patient: patientId !== sourcePatientId ? sourcePatientId : null,
                }).pipe(catchAndPropagateError(err =>
                    of({
                        fax: false,
                        sms: false,
                        billing_validation: null,
                        error: err,
                        taggable_users: [],
                    } as PatientTaskDetailsSerializer)
                )).subscribe(res => {
                    obs$.next({
                        fax: res.fax,
                        sms: res.sms,
                        billable: res.billing_validation?.is_valid_for_billing,
                        reasons: res.billing_validation?.billing_invalidity_reasons,
                        error: (res as any).error?.error.detail || null,
                        billing_code_category_names: res.billing_validation?.medical_programs?.map(x => x.display_name),
                        taggable_users: res.taggable_users,
                    });
                    obs$.complete();
                });
            } else {
                obs$.next({fax: false, sms: false, billable: false, reasons: []});
                obs$.complete();
            }
        });
        return obs$;
    }

    getTaskType$(key: string): Observable<TaskTypeMerged> {
        return this.taskTypesMerged$.pipe(take(1), map(() => this.taskTypeService.getTaskType(key)));
    }

    static getTaskTypeName(taskType: TaskTypeMerged): string {
        return taskType ? taskType.label : null;
    }

    getTasks(
        {page, forceFetch, filters}: {
            filters: TaskCacheFilters;
            page: number;
            forceFetch?: boolean;
        } = {filters: null, page: null, forceFetch: false}
    ) {
        const patientTasksCacheRepo = this._getTaskPagedRepo(filters);
        return combineLatest([
            patientTasksCacheRepo.count$,
            patientTasksCacheRepo.getData(page, forceFetch),
        ]).pipe(
            map(([count, tasks]) => ({count, tasks}))
        );
    }

    @MethodCache(taskCacheIdSegmentsFn)
    private _getTaskPagedRepo(filters: TaskCacheFilters): PagedCacheRepo<TaskSimpleSerializer, Task> {
        return new PagedCacheRepo<TaskSimpleSerializer, Task>({
            apiFunction: APIService.TaskViewSet.list,
            filters,
            pageSize: this._notePageSize,
            constructorClass: Task,
            sortingFn: Task.compareSort,
            ttlSecs: TTL_SECS,
        });
    }

    getTaskById(taskId): Observable<Task> {
        return Task.retrieve(taskId);
    }

    inType(type: string, inType: string) {
        if (type === inType) return true;

        const t = this.taskTypeService.taskTypesFlattened.find(x => x.key === inType);
        return t.children?.some(x => x.key === type) || false;
    }

    private _updatePatientTasks(x: {event: RepoAction; task: Partial<Task>}) {
        this._getTaskRepos().reduce<PagedCacheRepo<TaskSimpleSerializer, Task>[]>((acc, currentRepo) => {
            if (currentRepo.repoKey.includes(x.task.patient.id.toString())) {
                acc.push(currentRepo.repoValue);
            }
            return acc;
        }, [])?.forEach(
            cacheItem => {
                switch (x.event) {
                    case 'add': {
                        cacheItem.add(x.task);
                        break;
                    }
                    case 'remove': {
                        cacheItem.remove((x.task as Task));
                        break;
                    }
                    case 'update': {
                        cacheItem.update(x.task);
                    }
                }
            }
        );
    }

    initTaskEdit(task?: Task | TaskInit, options?: EditorOptions) {
        if (task && !task.id && task.patient && !task.source_patient && !(task instanceof Task)) {
            task = {...task, source_patient: task.patient, patient: undefined};
        }
        (task?.id ? Task.retrieve(task.id, true) : of(task || {})).subscribe(t => this.initTaskEdit$.next({task: t, options}));
    }

    downloadTask(task: Task, template: 'FACILITY' | 'NOTE' | 'VISIT_SUMMARY' | 'PATIENT_NOTE' | 'NOTE_WITH_BILLING' = 'NOTE') {
        if (this._user.roles.includes('ROLE_PATIENT') || this._user.roles.includes('ROLE_PATIENT_REPRESENTATIVE') && template === 'NOTE') template = 'PATIENT_NOTE';
        const obs$ = this.ds.downloadFileByUrl(APIService.urls.TaskViewSet.download_pdf(task.id, {
            fax: template === 'FACILITY',
            visit_summary: template === 'VISIT_SUMMARY',
            patient_note: template === 'PATIENT_NOTE',
            note_with_billing: template === 'NOTE_WITH_BILLING',
        })).pipe(
            share(),
            tap({error: () => this.toastService.error('An error occurred while trying to download file')}),
        );
        obs$.subscribe();
        return obs$;
    }

    downloadCCDA(task: Task, ccdaType: CcdaType) {
        const obs$ = this.ds.downloadFileByUrl(APIService.urls.TaskViewSet.download_as_ccd(task.id, {ccda_type: ccdaType} as TaskViewSetQueryParams /* Remove type cast when the TaskViewSetQueryParams interface is updated */))
            .pipe(share());
        obs$.subscribe();
        return obs$;
    }

    shouldAutoTagUser(user): boolean {
        return user.roles.some(role => this.autoTagRoles.includes(role));
    }

    hasFacilityForPatient(user, patient): boolean {
        return user.facilities.some(facility => facility === patient.facility);
    }

    repeatOrder(action: PhysicianOrderActionSerializer, task: TaskActionSerializer, extraTaskData: Partial<TaskCreateSerializer> = {}) {
        this.initTaskEdit({
            patient: getId(task.patient),
            type: 'NEW_ORDERS',
            description: task.description,
            note: task.note,
            physician_order: {actions: [action]},
            ...extraTaskData,
        });
    }

    sendTaskInEmail(taskId: number, email: PatientDocumentCDATransmitSerializer, ccdaType: CcdaType) {
        return this.api.TaskViewSet.generate_upload_ccd(taskId, {ccda_type: ccdaType})
            .pipe(
                switchMap(ccd => this.api.PatientDocumentCDAViewSet.transmit_cda(ccd.id, email)),
                tap(_ => this.toastService.success('E-mail successfully sent!')),
                take(1)
            );
    }

    private _getTaskRepos() {
        return Object.values(MethodUtils.getCacheEntry(this, 'getTasks')).reduce<{repoKey: string; repoValue: PagedCacheRepo<TaskSimpleSerializer, Task>}[]>(
            (acc, currentRepo) => acc.concat(Array.from(currentRepo.entries()).map(([repoKey, repoValue]) => ({repoKey, repoValue}))),
            []
        );
    }
}
