import {getQueryString} from '../@core/api.service';

export type SimpleQueryParams = {[key: string]: string | number};

export const getWebSocketUrl = (path = '', queryParams: SimpleQueryParams = {}) => {
    const wsScheme = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
    const hostName = window.location.hostname;
    return `${wsScheme}${hostName}/ws/${path}?${getQueryString({token: localStorage.getItem('jwt-token'), ...queryParams})}`;
};

export function getWebAddress(webAddress: string) {
    const lead: '' | '//' = webAddress.match(/^https?:\/\//) ? '' : '//';
    return lead + webAddress;
}
