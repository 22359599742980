import {Injectable} from '@angular/core';
import {services} from '@arsana/apis';
import {filter, map, share, switchMap, take} from 'rxjs/operators';
import {GRPCWebTransport} from './grpc-web/grpc-web.transport';
import {fromEvent, merge, Observable, of, Subject} from 'rxjs';
import {PatientService} from './patient.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {IframeComponent} from '../portal/@portal-theme/components/iframe/iframe.component';
import {Task, TaskStatus} from '../models/models';
import {NotificationsService} from './notifications.service';
import {ToastService} from './toast.service';
import {extractErrorMessage} from '../utils/error.utils';
import {SentryErrorHandler} from './sentry-error-handler';
import {APIService} from './api.service';

export type AyvaAuthorizationStatus = 'PENDING' | 'SUCCESS' | 'ERROR' | 'NO_USER' | 'CANCELED';

@Injectable({
    providedIn: 'root',
})
export class PharmacyService extends services.pharmacy.v1.PharmacyWebService {
    private _activeRxOrdersFinalization: {taskId: number; modalRef: NgbModalRef; updates$: Subject<Task>};

    constructor(transport: GRPCWebTransport,
                private patientService: PatientService,
                private modalService: NgbModal,
                private notificationsService: NotificationsService,
                private toastService: ToastService) {
        super(transport);

        this.notificationsService.otherEvents$.subscribe(msg => {
            if (msg.type === 'e_prescription_task_status_changed') this.handleTaskStatusChange(msg);
            if (msg.type === 'e_prescription_rx_order_status_changed') this.handleRxOrderStatusChange(msg);
        });
    }

    createPatientInErx(patientId: number) {
        const obs$ = this.patientService.getPatientDetail(patientId).eRxStatus$.pipe(
            take(1),
            this.handleAuthAndRetry,
            switchMap(() => APIService.PatientERXViewSet.create_patient_in_erx(patientId)),
            share(),
        );
        obs$.subscribe({
            next: () => {
                this.patientService.invalidate(patientId, 'eRxStatus');
                this.toastService.success('Patient Profile added to e-Rx successfully');
            },
            error: err => this.toastService.error(extractErrorMessage(err, 'add Patient Profile to e-Rx')),
        });
        return obs$;
    }

    openErxProfile(patientId: number) {
        this.patientService.getPatientDetail(patientId).eRxStatus$.pipe(
            take(1),
            this.handleAuthAndRetry,
        ).subscribe(res => {
            if (res.patient_encounter_url) {
                window.open(res.patient_encounter_url, '_blank');
            } else {
                this.toastService.warning('No e-Rx profile found for this patient');
            }
        });
    }

    handleTask(task: Task): Observable<Task> {
        if (task.e_prescribing?.error) {
            SentryErrorHandler.handleError(task.e_prescribing.error);
            this.toastService.error(extractErrorMessage(task.e_prescribing.error, 'process e-prescription'));

            return of(task);
        }

        if (task.e_prescribing?.authorization_url) {
            const auth$ = this.openAuthorization(task.e_prescribing.authorization_url);
            this.toastService.info('You need to authorize Arsana to access your account to be able to post e-prescriptions');

            return merge(of(task), auth$.pipe(filter(x => x === 'SUCCESS'), map(() => Object.assign(task, {meta: {saveAgain: true}}))));
        }

        if (task.e_prescribing?.finalize_url) {
            if (this._activeRxOrdersFinalization) this._activeRxOrdersFinalization.updates$.complete();

            this._activeRxOrdersFinalization = {
                taskId: task.id,
                modalRef: this.openRxOrdersFinalization(task.e_prescribing.finalize_url),
                updates$: new Subject<Task>(),
            };
            this.toastService.info("After finalizing your e-prescriptions, you no longer will be able to edit this note. Make sure you don't want to make further changes", 'Editing this note will be disabled');

            return merge(of(task), this._activeRxOrdersFinalization.updates$);
        }

        return of(task);
    }

    openRxOrdersFinalization(url: string) {
        const ref = this.modalService.open(IframeComponent, {size: 'xl'});
        ref.componentInstance.url = url;
        return ref;
    }

    handleTaskStatusChange(msg: {task_id: number; status: TaskStatus}) {
        const task: Task = Task.get(msg.task_id);

        if (!task) return;

        task.status = msg.status;

        if (task.id === this._activeRxOrdersFinalization?.taskId) {
            this._activeRxOrdersFinalization.updates$.next(task);

            if (task.status === 'SAVED') {
                this._activeRxOrdersFinalization.updates$.complete();
                this._activeRxOrdersFinalization.modalRef.close();
                this._activeRxOrdersFinalization = null;

                this.toastService.success('E-prescription processed successfully');
            } else if (task.status === 'RX_FINALIZATION_ERROR') {
                this.toastService.error('An error occurred during e-prescription processing', 'Error');
            }
        }
    }

    handleRxOrderStatusChange(msg) {

    }

    openAuthorization(url?: string) {
        const url$ = of(url);
        // const url$ = url ? of(url) : this.GetAuthorizationURL$().pipe(map(res => res.url));
        const obs$ = url$.pipe(
            switchMap(url => {
                const ref = this.modalService.open(IframeComponent, {size: 'lg', backdrop: 'static', keyboard: false});
                const iframe: IframeComponent = ref.componentInstance;
                iframe.url = url;
                iframe.dismiss = () => {
                    this.emitAuthorizationResponse('CANCELED');
                    this.closeAuthorization();
                };

                fromEvent(window, 'ayvaAuthorizationClose').pipe(take(1)).subscribe(() => ref.close());

                return fromEvent(window, 'ayvaAuthorizationResponse').pipe(take(1), map((e: any) => (e.detail as AyvaAuthorizationStatus)));
            }),
            share(),
        );
        obs$.subscribe(() => this.patientService.invalidateKey('eRxStatus'));
        return obs$;
    }

    emitAuthorizationResponse(status: AyvaAuthorizationStatus, target: Window = window) {
        target?.dispatchEvent(new CustomEvent('ayvaAuthorizationResponse', {detail: status}));
    }

    closeAuthorization(target: Window = window) {
        target?.dispatchEvent(new CustomEvent('ayvaAuthorizationClose'));
    }

    handleAuthAndRetry = <T extends {authorization_url?: string}>(input$: Observable<T>) => input$.pipe(
        switchMap(res => {
            if (!res?.authorization_url) return of(res);

            this.toastService.info('You need to authorize Arsana to access your account in order to proceed');

            return this.openAuthorization(res.authorization_url).pipe(
                filter(x => x === 'SUCCESS'),
                switchMap(() => input$),
            );
        }),
    );
}
