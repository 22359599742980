import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {NotificationsService} from '../../../@core/notifications.service';
import {NotificationReadSerializer} from '../../../@core/api.service';

export class Notification implements NotificationReadSerializer {
    id: number;
    message: any;
    html_message: any;
    timestamp: Date;
    timesince: any;
    notification_type: string;
    level: any;
    unread: boolean;
    target_object_id: number;
    patient: number;
    facility: number;
    icon: string;
    color: 'warning' | 'info' | 'success' | 'danger';
    action: () => any;
    hasDetails: boolean;
    isOpen = false;
    private readonly _getObjectObs: () => ReplaySubject<any>;
    private _object$: ReplaySubject<any>;

    constructor(noti?: NotificationReadSerializer, private notiService?: NotificationsService) {
        if (noti) Object.assign(this, noti);
        if (this.notiService) {
            if (!this.icon) this.icon = this.notiService.getProperty(this, 'icon');
            if (!this.action) this.action = this.notiService.getProperty(this, 'action');
            this._getObjectObs = this.notiService.getProperty(this, 'getObject');
            if (this._getObjectObs) this.hasDetails = false;
            this.color = this.notiService.getProperty(this, 'color');
        }
    }

    setRead() {
        if (this.notiService) {
            this.notiService.setRead(this);
            this.unread = false;
        }
    }

    toggleDetails() {
        this.isOpen = this.hasDetails ? !this.isOpen : false;
    }

    get colorClass(): string {
        return this.unread ? this.color || 'brand-3' : 'neutral';
    }

    get object$(): ReplaySubject<any> {
        if (!this._getObjectObs) return null;
        if (!this._object$) this._object$ = this._getObjectObs();
        return this._object$;
    }
}

@Component({
    selector: 'app-notification',
    template: `
        <div [attr.class]="'card raised-dark border-' + notification.colorClass + (notification.unread && ' has-read-toggle')" (click)="onNotificationClick($event)" style="cursor: pointer">
            <div class="toggle" (click)="$event.stopPropagation(); notification.setRead()" *ngIf="notification.unread">
                <fas i="circle" class="default-icon"></fas>
                <fas i="check-circle" class="hover-icon"></fas>
            </div>
            <div class="d-flex flex-row align-items-center">
                <div class="icon-section">
                    <fal [i]="notification.icon"></fal>
                </div>
                <div class="card-body">
                    <span class="text-muted">{{notification.timestamp|tzDate:'fromNow'}}</span>
                    <span class="message" [innerHTML]="notification.html_message"></span>
                </div>
                <app-action-icon *ngIf="notification.hasDetails" tooltip="Details"
                                 icon="chevron-{{notification.isOpen ? 'up' : 'down'}}" [active]="notification.isOpen"
                                 (click)="$event.stopPropagation(); notification.toggleDetails()"
                                 style="position: relative; z-index: 1; margin: 0 8px;"></app-action-icon>
            </div>
            <div *ngIf="notification.isOpen" class="p-3" (click)="$event.stopPropagation()" style="position: relative; z-index: 1; cursor: default;">
<!--                <app-ccm-note-list *ngIf="['ccm_note', 'ccm_note_tagged', 'fax_send_failed'].includes(notification.notification_type) && notification.object$|async"-->
<!--                                   [tasks]="[notification.object$|async]"-->
<!--                                   (resolveTask)="notification.unread ? notification.setRead() : null"-->
<!--                                   [hasPatient]="true" [hasDateHeading]="false"></app-ccm-note-list>-->
            </div>
        </div>
    `,
    styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
    @Input() notification: Notification;
    @Output() notificationClick = new EventEmitter();

    onNotificationClick(event) {
        event.stopPropagation();
        this.notification.setRead();
        const action = this.notification.action;
        if (action) {
            action();
            this.notificationClick.emit();
        }
    }
}
