import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {FloatingActionKey} from '../portal/floating-actions/floating-actions.component';
import {LoginModalComponent} from '../portal/@portal-theme/components/login-modal/login-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {JWTService} from './jwt.service';

@Injectable({
    providedIn: 'root',
})
export class OverlayService {
    action$ = new Subject<string>();
    status: string;

    constructor(private modalService: NgbModal,
                private jwt: JWTService) {
        this.jwt.loginRequest$.subscribe(subj => {
            this.jwt.loginModal = this.modalService.open(LoginModalComponent, {backdrop: 'static', keyboard: false});

            this.jwt.loginModal.result
                .then(() => subj.next('update_tokens'))
                .catch(action => subj.next(action));
        });
    }

    openFab(key: FloatingActionKey) {
        this.emit(key);
    }

    emit(event: string) {
        this.status = event;
        this.action$.next(event);
    }
}
